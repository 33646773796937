import axios from "../../../axios/index.js"

export default {
    init() {
        axios.interceptors.response.use(function (response) {
            return response
        }, function (error) {
            return Promise.reject(error)
        })
    },
    login(email, pwd, remember_me) {
        return axios.post("g/auth/login", { email: email, password: pwd, remember_me: remember_me })
    },
    inviteUser(email, acc, intentions, entities, acl) {
        return axios.post("g/user/invite", { email: email, acc: acc, intentions: intentions, entities, acl: acl || "admin" })
    },
    registerInvitedUser(name, phone, email, password, invatation_id) {
        return axios.post("g/user/invited/update", { name: name, phone: phone, email: email, password: password, invatation: invatation_id })
    },
    registerUser(name, phone, email, pwd) {
        return axios.post("g/auth/register", { name: name, phone: phone, email: email, password: pwd })
    },
    setupUserAcc(name, phone, email, password, plan, companyName, companyIndustry, companyEmployees) {
        return axios.post("g/auth/setup", { name: name, phone: phone, email: email, password: password, plan: plan, companyName: companyName, companyIndustry: companyIndustry, companyEmployees: companyEmployees })
    },
    refreshToken() {
        return axios.post("g/auth/refresh-token", { token: '' })
    },
    resetPassword(email) {
        return axios.post("g/auth/reset-password", { email: email })
    },
    getPlans(product, whitelabel) {
        return axios.get("g/product/plans", { params: { product: product, whitelabel: whitelabel } })
    },
    getInvitedUser(id) {
        return axios.get("g/user/invite/" + id)
    },
    async getAcc() {
        return await axios.get("g/acc/list")
    },
    resendVerificationCode(email) {
        return axios.post("g/auth/resend-verify-otp", { email: email })
    },
    verifyUser(email, otp) {
        return axios.post("g/auth/verify-otp", { email: email, otp: otp })
    },
}