/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State

==========================================================================================*/

const state = {
  accessToken: null,
  refreshToken: null
}

export default state