/*=========================================================================================
  File Name: moduleAcc.js
  Description: Acc Module

==========================================================================================*/


import state from './moduleAccState.js'
import mutations from './moduleAccMutations.js'
import actions from './moduleAccActions.js'
import getters from './moduleAccGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
