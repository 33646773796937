/*=========================================================================================
  File Name: moduleContactState.js
  Description: Contact Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  contacts: [],
  contactsPhase: [],
  count: 0,
  activeProfileSidebar: false,
  phase: [],
  contactUN: [],
  contactsConcated: []
}
