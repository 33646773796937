import { Validator } from 'vee-validate'

import English from 'vee-validate/dist/locale/en'
import Portuguese from 'vee-validate/dist/locale/pt_BR'

// LOCALIZATION
const dict = {
  en: { custom: { honorificPrefix: { mrs: 'Mrs', mr: 'Mr' } } },
  br: { custom: { honorificPrefix: { mrs: 'Sra', mr: 'Sr' } } },
}

Validator.localize('en', dict.en)
Validator.localize('br', dict.br)

export default function appValidationDictionarySetup (validator) {
  validator.localize('en', {
    messages: English.messages,
    attributes: {
      givenName: 'First name',
      familyName: 'Name',
      messageContent: 'Message',
      companyName: 'company name',
      segment: 'industry',
      otherSegment: 'industry'
    },
    custom: {
      message: () => 'Message cannot be empty',
      select: 'Select field is required',
      correct_all: 'Please correct all errors in your form',
      invalidCoupon: 'Coupon code is not valid',
      error: 'Error',
      axiosPostError: 'Oops! An error occured and your message could not be sent.',
      contactMsgSent: 'Your message has been successfully sent. We\'ll be in touch soon.'
    }
  })

  var ObjTranslate = {
    messages: Portuguese.messages,
    attributes: {
      email: 'e-mail',
      name: 'nome',
      messageContent: 'mensagem',
      new_password: 'nova senha',
      confirm_new_password: 'confirmar nova senha',
      confirmPassword: 'confirmar senha',
      password: 'senha',
      plan: 'plano',
      company: 'empresa',
      companyName: 'nome da empresa',
      segment: 'segmento',
      otherSegment: 'segmento',
      phone: 'telefone'
    },
    custom: {
      message: 'Messagem não pode estar vazio',
      required: 'Este campo é obrigatório',
      select: 'Selecione campo é obrigatório',
      correct_all: 'Por favor, corrija todos os erros em seu formulário',
      invalidCoupon: 'Cupom inválido',
      error: 'Erro',
      axiosPostError: 'Opa! Ocorreu um erro e sua mensagem não pôde ser enviada.',
      success: 'Obrigado',
      contactMsgSent: 'Sua mensagem foi enviada com sucesso. Nós entraremos em contato em breve.'
    }
  };
  ObjTranslate.messages.required = ObjTranslate.custom.required;
  validator.localize('pt-br',ObjTranslate)
  validator.localize('pt_BR',ObjTranslate)
}