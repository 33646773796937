/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations

==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
  SET_BEARER(state, accessToken) {
    state.accessToken = accessToken;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  LOGOUT(state){
    state.accessToken = null;
  }
}
