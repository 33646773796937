import EventEmitter from 'events'

class AuthService extends EventEmitter {
    renewTokens() {
        return new Promise((reject) => {
            if (!localStorage.getItem('accessToken')) {
                return reject("Not logged in");
            }
        });
    }
    isAuthenticated() {
        return (localStorage.getItem('accessToken') !== null);
    }
}

export default new AuthService();
