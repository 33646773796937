// axios
import axios from "axios";
import router from "./router";
import store from "./store/store";

const domain = process.env.VUE_APP_API_URL;
const version = store.state.appVersion;

let headers = { "Front-Version": version };
let path = router.currentRoute.path;
if (router.currentRoute.query) {
  path +=
    "?" +
    Object.keys(router.currentRoute.query)
      .map(function (k) {
        return (
          encodeURIComponent(k) +
          "=" +
          encodeURIComponent(router.currentRoute.query[k])
        );
      })
      .join("&");
}
if (!store.state.auth.accessToken) {
  if (router.currentRoute.name !== "page-login") {
    router.push({ name: "page-login", query: { to: path } }).catch(() => {});
  }
}
let axiosInstance = axios.create({
  baseURL: domain,
  headers,
  timeout: 30000,
});

axiosInstance.interceptors.response.use(function (response) {
    if (response.data.frontVersionConflict === true) {
      let url = window.location.href;
      const urlObj = new URL(url);
      if (!urlObj.searchParams.get("cc") && process.env.NODE_ENV === 'production') {
        if (url.indexOf('?') > -1) url += '&cc=1'
        else url += '?cc=1'
        window.location.href = url;
      }
    }
    return response;
  },
  function (error) {
    var response = null;
    if (error.request.status === 406) {
      response = JSON.parse(error.request.response);
      //if (response.error == 'has_debit') router.push({ name: "page-plans" }).catch(() => { });
    } else if (error.request.status > 400) {
      response = JSON.parse(error.request.response);
      if (
        response.message === "jwt expired" ||
        response.message === "invalid signature" ||
        response.message === "jwt malformed" ||
        response.message === "invalid token"
      ) {
        store.dispatch("auth/logOut");
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
