/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.

==========================================================================================*/


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'

Vue.use(VueI18n)

export default new VueI18n({
  locale:  'pt_BR',
  messages: i18nData,
})
