import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller)

let initialRole = "public"

let userInfo = JSON.parse(localStorage.getItem("userInfo"))
if(userInfo && userInfo.userRole) initialRole = userInfo.userRole
export default new AclCreate({
  initial: initialRole,
  notfound: "/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    master: new AclRule("master").generate(),
    admin: new AclRule("admin").or("master").generate(),
    manager: new AclRule("manager").or("master").or("admin").generate(),
    operator: new AclRule("operator").or("manager").or("admin").or("master").generate(),
    public: new AclRule("public").or("operator").or("manager").or("admin").or("master").generate(),
  }
})
