/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions

==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import router from "@/router";
import i18n from "@/i18n/i18n.js";
import axios from "axios";

export default {
  setToken({ commit }, token) {
    commit("SET_BEARER", token);
  },
  setRefreshToken({ commit }, token) {
    commit("SET_REFRESH_TOKEN", token);
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.fistName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit("UPDATE_USER_INFO", newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: "Erro",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },
  logOut({ commit }) {
    commit("LOGOUT");

    try {
      axios.post(
        `${process.env.VUE_APP_API_URL}g/user/notification/remove-token`,
        {
          token: localStorage.getItem("firebaseToken"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
    } catch (e) {
      //nothing to do
    }

    const deviceID = localStorage.getItem("deviceID");
    localStorage.clear();
    if (deviceID !== null) localStorage.setItem("deviceID", deviceID);
    let path = router.currentRoute.path;
    if (router.currentRoute.query) {
      path +=
        "?" +
        Object.keys(router.currentRoute.query)
          .map(function (k) {
            return (
              encodeURIComponent(k) +
              "=" +
              encodeURIComponent(router.currentRoute.query[k])
            );
          })
          .join("&");
    }
    if (router.currentRoute.name === "page-logout") path = "";
    if (router.currentRoute.name !== "page-login")
      router.push({ name: "page-login", query: { to: path } }).catch(() => {});
    return new Promise(() => {
      if (!router.currentRoute.query.to) location.reload();
    });
  },
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(
          payload.userDetails.email,
          payload.userDetails.password,
          payload.remember_me
        )
        .then((response) => {
          if (typeof response.data.data.accessToken !== "undefined") {
            if (response.data.data.refreshToken)
              localStorage.setItem(
                "refreshToken",
                response.data.data.refreshToken
              );
            localStorage.setItem("accessToken", response.data.data.accessToken);
            localStorage.setItem("loggedIn", true);
            localStorage.removeItem("verifyUser");

            commit("UPDATE_USER_INFO", response.data.data, { root: true });
            commit("SET_BEARER", response.data.data.accessToken);
            if (response.data.data.refreshToken)
              commit("SET_REFRESH_TOKEN", response.data.data.refreshToken);

            var language = response.data.data.lang;
            language = language.search("en") !== -1 ? "en" : language;
            if (i18n.availableLocales.indexOf(language) > -1)
              i18n.locale = language;
            else i18n.locale = "en";

            jwt
              .getAcc()
              .then((response) => {
                if (typeof response.data.data[0] !== "undefined") {
                  localStorage.setItem("activeAcc", response.data.data[0]._id);
                  localStorage.setItem(
                    "activeAccInfo",
                    JSON.stringify({
                      id: response.data.data[0]._id,
                      name: response.data.data[0].name,
                    })
                  );

                  resolve(response);
                } else reject(response);
              })
              .catch((error) => {
                if (typeof error.response !== "undefined") {
                  if (typeof error.response.data !== "undefined")
                    reject(error.response.data);
                  else reject(error.response);
                } else reject(error);
              });
          } else if (
            typeof response.data.data.isConfirmed !== "undefined" &&
            response.data.data.isConfirmed == false
          ) {
            localStorage.setItem("verifyUser", JSON.stringify(payload));
            resolve("verify");
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyUser({ dispatch }, payload) {
    var objVerifyUser = JSON.parse(localStorage.getItem("verifyUser"));
    const { otp } = payload.userDetails;
    return new Promise((resolve, reject) => {
      try {
        jwt
          .verifyUser(objVerifyUser.userDetails.email, otp)
          .then((response) => {
            if (response.data.status === 1) {
              dispatch("loginJWT", objVerifyUser);
              resolve(true);
            } else {
              reject(response.data.data);
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  resentVerificationCode() {
    var objVerifyUser = JSON.parse(localStorage.getItem("verifyUser"));
    return new Promise((resolve, reject) => {
      try {
        jwt
          .resendVerificationCode(objVerifyUser.userDetails.email)
          .then((response) => {
            if (response.data.status === 1) {
              resolve(true);
            } else {
              reject(response.data.data);
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  setupJWT(n, payload) {
    const {
      name,
      phone,
      email,
      password,
      companyName,
      companyIndustry,
      companyEmployees,
      plan,
    } = payload.userDetails;
    return new Promise((resolve, reject) => {
      try {
        jwt
          .setupUserAcc(
            name,
            phone,
            email,
            password,
            plan,
            companyName,
            companyIndustry,
            companyEmployees
          )
          .then((response) => {
            if (response.data.status === 1) {
              resolve(true);
            } else {
              reject(response.data.data);
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  inviteUserJWT(n, payload) {
    const { email, acc, intentions, entities, acl } = payload.userDetails;
    return new Promise((resolve, reject) => {
      try {
        jwt
          .inviteUser(email, acc, intentions, entities, acl)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  registerUserInvatationJWT(n, payload) {
    const { name, phone, email, password, invatation_id } = payload.userDetails;
    return new Promise((resolve, reject) => {
      try {
        jwt
          .registerInvitedUser(name, phone, email, password, invatation_id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  registerUserJWT({ commit }, payload) {
    const { name, phone, email, password, confirmPassword } =
      payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(name, phone, email, password)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || "/");

          // Update data in localStorage
          localStorage.setItem("accessToken", response.data.accessToken);
          commit("UPDATE_USER_INFO", response.data.userData, { root: true });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccessToken() {
    return new Promise((resolve, reject) => {
      jwt
        .refreshToken()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword({ commit }, payload) {
    const { email } = payload.userDetails;

    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(email)
        .then((response) => {
          commit("UPDATE_USER_INFO", "", { root: true });

          resolve(response);
          payload.notify({
            time: 2500,
            title: "Ok",
            text: payload.$t("ResetPasswordMessage"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchInvitedUser(n, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .getInvitedUser(payload.id)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetPlans({ commit }, payload) {
    return new Promise((resolve) => {
      commit("UPDATE_USER_INFO", "", { root: true });
      jwt.getPlans(payload.product, payload.whitelabel).then((response) => {
        resolve(response);
      });
    });
  },
};
