/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken as getTokenFirebase, deleteToken as deleteTokenFirebase } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDADaXACd78GbXXFxIZkU8K94mpYLdNUxs",
  authDomain: "duotalk-app.firebaseapp.com",
  projectId: "duotalk-app",
  storageBucket: "duotalk-app.appspot.com",
  messagingSenderId: "392140658046",
  appId: "1:392140658046:web:8d7ea311038a61b95d4f2f",
  measurementId: "G-PEPQFZX13F"
};
let messaging;
let getToken = async () => { };
let deleteToken = async () => { };

// Initialize Firebase
if (process.env.VUE_APP_FIREBASE_ENABLED === "true") {

  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);

  getToken = async () => {
    let currentToken = '';

    try {
      currentToken = await getTokenFirebase(messaging, { vapidKey: process.env.VUE_APP_VAPID_KEY })
      return currentToken;
    } catch (e) {
      //console.log("Firebase token error", e);
    }
  }

  deleteToken = async () => {
    return await deleteTokenFirebase(messaging);
  }
}


export { messaging, getToken, deleteToken }