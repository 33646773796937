/*=========================================================================================
  File Name: moduleContactMutations.js
  Description: Contact Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    SET_ACTIVE_PROFILE_SIDEBAR(state, data) {
        state.activeProfileSidebar = data;
    },
    SET_CONTACTS(state, contacts) {
        state.contacts = contacts
    },
    SET_COUNT(state, count) {
        state.count = count
    },
    SET_PHASES(state, data) {
        state.phase = data
    },
    UPDATE_CONTACT_BEFORE(state, contact) {
        let index = state.contacts.findIndex((item) => item._id === contact._id);
        if (index > -1) {
            state.contacts.splice(index, 1);
        }
    },
    UPDATE_CONTACT_AFTER(state, contact) {
        state.contacts.splice(state.contacts.length-1, 0, contact);
    },
    UPDATE_CONTACT_PROP(state, { index, prop, value }) {
        if (state.contacts[index]) state.contacts[index][prop] = value;
    },
    UPDATE_CONTACT_PHASE(state, contact) {
        let index = state.contactsPhase.findIndex((item) => item._id === contact._id);
        if (index > -1) {
            state.contactsPhase.splice(index, 1)
            state.contactsPhase.splice(index, 0, contact);
        }
    },
    UPDATE_CONTACT_PHASE_PROP(state, { index, prop, value }) {
        if (state.contactsPhase[index])
            state.contactsPhase[index][prop] = value;
    },
    CONTACT_CONTACT_PHASE_REFRESH(state, index) {
        if (state.contactsPhase[index]) {
            state.contactsPhase.push(state.contactsPhase[index])
            state.contactsPhase.splice(state.contactsPhase.length - 1, 1);
        }
    },
    SET_NEW_PHASE(state, contact) {
        let index = state.contacts.findIndex((item) => item._id === contact.contact._id);
        state.contacts[index].phase = contact.newPhase;
    },
    SET_CONTACT_PHASE(state, data) {
        state.contactsPhase = data;
    },
    SET_CONTACT_IN_PHASE(state, contact) {
        state.contactsPhase.push(contact);
    },
}
