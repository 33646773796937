/*=========================================================================================
  File Name: store.js
  Description: Vuex store

==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
import moduleChat from './chat/moduleChat.js'
import moduleAcc from './acc/moduleAcc.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleContact from './contact/moduleContact.js'


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    chat: moduleChat,
    acc: moduleAcc,
    auth: moduleAuth,
    contact: moduleContact,
  },
  strict: process.env.NODE_ENV !== 'production'
})
